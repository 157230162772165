import Bugsnag from 'src/utils/Bugsnag';
import app from 'src/containers/AppBase/sagas';
import addSkuPage from 'src/containers/AddSkuPage/sagas';
import homePage from 'src/containers/HomePage/sagas';
import customerReviewsPage from 'src/containers/CustomerReviewsPage/sagas';
import loginPage from 'src/containers/LoginPage/LoginForm/sagas';
import planPageAlternate from 'src/containers/Plans/PlanPageAlternate/sagas';
import registrationPage from 'src/containers/RegistrationPage/sagas';
import skuCategoryPage from 'src/containers/SkuCategoryPage/sagas';
import skuPage from 'src/containers/SkuPage/sagas';
import accountPage from 'src/containers/Account/AccountPage/sagas';
import ordersPage from 'src/containers/Account/OrdersPage/sagas';
import accountOrderPage from 'src/containers/Account/OrderPage/sagas';
import devicesPage from 'src/containers/Account/DevicesPage/sagas';
import accountScoreCard from 'src/containers/Account/HomeScoreCardPage/sagas';
import bookingPage from 'src/containers/BookingPage/sagas';
import bookingPagePaloAltoNetworks from 'src/containers/BookingPagePaloAltoNetworks/sagas';
import reschedulingPage from 'src/containers/ReschedulingPage/sagas';
import orderConfirmationPage from 'src/containers/OrderConfirmationPage/sagas';
import cartPage from 'src/containers/CartPage/sagas';
import accountPlanPage from 'src/containers/Account/PlanPage/sagas';
import paymentPage from 'src/containers/Account/PaymentPage/sagas';
import techMapPage from 'src/containers/Account/TechMapPage/sagas';
import createPasswordPage from 'src/containers/CreatePasswordPage/sagas';
import landingPage from 'src/containers/LandingPage/sagas';
import customerSurveyPage from 'src/containers/CustomerSurvey/sagas';
import locationPage from 'src/containers/LocationPage/sagas';
import geekSquadPage from 'src/containers/GeekSquadPage/sagas';
import microsoftPage from 'src/containers/MicrosoftPage/sagas';
import firstServiceDiscountModal from 'src/components/HTKit/Modals/FirstServiceDiscount/sagas';
import redeemPlanPage from 'src/containers/Plans/RedeemPage/sagas';
import finishPartnerOrder from 'src/containers/FinishPartnerOrderPage/sagas';
import ahsValidationPage from 'src/containers/AHSValidationPage/sagas';
import bookNowButton from 'src/components/BookNowButton/sagas';
import surveyMonkey from 'src/components/SurveyMonkey/sagas';
import XfinityTech from 'src/containers/ComcastPage/XfinityTechPage/sagas';
import techTracking from 'src/containers/Account/TechMapPage/TechTrackingWS/sagas';
import subscriptionGifting from 'src/containers/SubscriptionGiftingPage/sagas';
import techLocationWS from 'src/containers/Account/OrderPage/Parts/Tech/TechLocation/TechTrackingWS/sagas';
import zipCodeValidationPage from 'src/containers/ZipCodeValidationPage/sagas';
import landingPageTest from 'src/containers/SimpliSafeLandingPage/sagas';
import getStatusPage from 'src/containers/GetOrderStatusPage/sagas';
import speedTestPage from 'src/containers/SpeedTestPage/sagas';
import pinRedemptionPage from 'src/containers/FinishOrder/PINRedemption/sagas';
import footer from 'src/components/Footer/sagas';
import fieldSalesPage from 'src/containers/FieldSales/sagas';
import availabilitySelector from 'src/components/AvailabilitySelector/sagas';
import selectWorkerPage from 'src/containers/BookingPage/SelectWorkerPage/sagas';

import formFlow from './common/form';
import cartFlow from './common/cart';
import pagesFlow from './pagesFlow';
// import gtmFlow from './common/gtm';

const addSagas = (store) => {
  const allSagas = [
    app,
    pagesFlow,
    addSkuPage,
    homePage,
    customerReviewsPage,
    loginPage,
    planPageAlternate,
    registrationPage,
    skuCategoryPage,
    skuPage,
    accountPage,
    ordersPage,
    accountOrderPage,
    devicesPage,
    accountScoreCard,
    bookingPage,
    bookingPagePaloAltoNetworks,
    reschedulingPage,
    cartPage,
    accountPlanPage,
    paymentPage,
    techMapPage,
    orderConfirmationPage,
    createPasswordPage,
    landingPage,
    formFlow,
    customerSurveyPage,
    redeemPlanPage,
    locationPage,
    geekSquadPage,
    microsoftPage,
    firstServiceDiscountModal,
    finishPartnerOrder,
    ahsValidationPage,
    // gtmFlow,
    bookNowButton,
    surveyMonkey,
    XfinityTech,
    subscriptionGifting,
    techTracking,
    techLocationWS,
    zipCodeValidationPage,
    landingPageTest,
    cartFlow,
    getStatusPage,
    speedTestPage,
    pinRedemptionPage,
    footer,
    fieldSalesPage,
    availabilitySelector,
    selectWorkerPage,
  ];

  allSagas.forEach((sagas) => {
    sagas.forEach((saga) => {
      store
        .runSaga(saga)
        .toPromise()
        .catch((e) => {
          if (!process.browser) return;
          try {
            Bugsnag.notifyException(e, store);
          } catch (e1) {
            console.log(e1); // eslint-disable-line
          }
        });
    });
  });
};
export default addSagas;
